import React from 'react';
import AuthNav from './Backend/AuthNav';
import TopBar from './TopBar';
import AuthFooter from './Backend/AuthFooter';

export default function AuthLayout({ children }) {
  return (
    <>
        <TopBar />
        <AuthNav />
        <main>{children}</main>
        <AuthFooter />
    </>
  );
}