import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LogoutBtn from './LogoutBtn'

export default function AuthNav() {
  const [activePage, setActivePage] = useState('dash');
  const [isNavOpen, setIsNavOpen] = useState(false);

  const showMobileNavBar = () => setIsNavOpen(!isNavOpen);

  const hideMobileNavBar = () => setIsNavOpen(false);

  return (
    <header id="header" className="d-flex align-items-center">
      <div className="container d-flex align-items-center justify-content-between">
        <h1 className="logo">
          <Link to="/EmployeeDashboard" onClick={() => setActivePage('dash')}>
            <span>Barakah</span>Byte<span>.</span>
          </Link>
        </h1>
        <nav id="navbar" className={`navbar ${isNavOpen ? 'navbar-mobile' : ''}`}>
          <ul>
            <li onClick={hideMobileNavBar}>
              <Link
                className={`nav-link ${activePage === 'dash' ? 'active' : ''}`}
                to="/EmployeeDashboard"
                onClick={() => setActivePage('dash')}
              >
                Dashboard
              </Link>
            </li>
            <li onClick={hideMobileNavBar}>
              <Link
                className={`nav-link ${activePage === 'profile' ? 'active' : ''}`}
                to="/EmployeeProfile"
                onClick={() => setActivePage('profile')}
              >
                Profile
              </Link>
            </li>
            <li onClick={hideMobileNavBar}>
              <LogoutBtn />
            </li>
          </ul>
          <i
            className={`bi ${isNavOpen ? 'bi-x' : 'bi-list'} mobile-nav-toggle`}
            onClick={showMobileNavBar}
          ></i>
        </nav>
      </div>
    </header>
  );
}