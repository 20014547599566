import { jwtDecode } from 'jwt-decode';
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';

export default function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
        document.title = "Login - BarakahByte";
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        const loginData = {
            username: username.trim(),
            password: password.trim(),
        };
        try {
            const endPoint = process.env.REACT_APP_API_URL || 'https://barakahbyte.in/';
            const apiUrl = `${endPoint}AuthAPI/Logon`;
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData),
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.Message || 'Login failed');
            }
            const jwt = await response.text();
            login(jwt);
            const decoded = jwtDecode(jwt);
            if (decoded && decoded.exp * 1000 > Date.now()) {
                navigate('/EmployeeDashboard', { replace: true });
            } else {
                throw new Error('Token Expired');
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className="vh-100 gradient-custom" style={{ backgroundColor: "#106eea" }}>
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                        <div className="card bg-dark text-white" style={{ borderRadius: "1rem" }}>
                            <div className="card-body p-5 text-center">
                                <div className="mb-md-5 mt-md-4 pb-5">
                                    <h2 className="fw-bold mb-2">
                                        <span style={{ color: "#106eea" }}>Barakah</span>Byte<span>.</span> Login
                                    </h2>
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-floating mb-3">
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                id="userName" 
                                                placeholder="Username" 
                                                maxLength={10}
                                                pattern='BYTE[0-9]{6}' 
                                                required 
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)} 
                                            />
                                            <label htmlFor="userName" className="text-dark">Username</label>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <input 
                                                type="password" 
                                                className="form-control" 
                                                id="userPass" 
                                                placeholder="Password" 
                                                maxLength={25} 
                                                required  
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)} 
                                            />
                                            <label htmlFor="userPass" className="text-dark">Password</label>
                                        </div>
                                        <div>
                                            <button className="btn btn-outline-light btn-lg px-5" type="submit" disabled={loading}>
                                                {loading ? 'Logging in...' : 'Login'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}