import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { AuthContext } from '../AuthContext';

export default function Dashboard() {
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useContext(AuthContext);
  useEffect(() => {
    document.title = "Dashboard - BarakahByte";

    if (!isAuthenticated) {
      navigate('/Login');
      return;
    }

    try {
      const decodedToken = jwtDecode(localStorage.getItem('BByteAuthToken'));

      // Check token expiration
      const currentTime = Math.floor(Date.now() / 1000);
      if (decodedToken.exp < currentTime) {
        logout();
        navigate("/Login");
        return;
      }

      setUserName(decodedToken.Name || 'User');
    } catch (err) {
      logout();
      navigate('/Login');
    }
  }, [navigate, isAuthenticated, logout]);

  return (
    <div className="container mt-5">
      <h1>Welcome {userName} to the <span style={{color: '#106eea'}}>Barakah</span>Byte<span style={{color: '#106eea'}}>.</span></h1>
      <div className="mt-4">
        <p>This is your central hub for managing your activities at BarakahByte.</p>
        <p>Use the navigation to access your profile, tasks, and more.</p>
      </div>
    </div>
  );
}