import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from './components/AuthContext';
import './App.css';
import About from './components/About';
import Contacts from './components/Contacts';
import Home from './components/Home';
import Services from './components/Services';
import Teams from './components/Teams';
import Maintenance from './components/Maintenance';
import Portfolio from './components/Portfolio';
import PortfolioDetails from './components/PortfolioDetails';
import RequestQuote from './components/RequestQuote';
import Login from './components/Login';
import Dashboard from './components/Backend/Dashboard';
import AuthLayout from './components/AuthLayout';
import PublicLayout from './components/PublicLayout';
import Profile from './components/Backend/Profile';

function App() {
    const { isAuthenticated } = useContext(AuthContext);

    return (
        <Router>
            <Routes>
                {/* Public Routes */}
                <Route path="/" element={<PublicLayout><Home /></PublicLayout>} />
                <Route path="/AboutUs" element={<PublicLayout><About /></PublicLayout>} />
                <Route path="/OurServices" element={<PublicLayout><Services /></PublicLayout>} />
                <Route path="/Teams" element={<PublicLayout><Teams /></PublicLayout>} />
                <Route path="/ContactUs" element={<PublicLayout><Contacts /></PublicLayout>} />
                <Route path="/UnderMaintenance" element={<PublicLayout><Maintenance /></PublicLayout>} />
                <Route path="/OurPortfolios" element={<PublicLayout><Portfolio /></PublicLayout>} />
                <Route path="/PortFolioDetails" element={<PublicLayout><PortfolioDetails /></PublicLayout>} />
                <Route path="/RequestQuotes" element={<PublicLayout><RequestQuote /></PublicLayout>} />
                <Route path="/Login" 
                       element={isAuthenticated 
                                ? <Navigate to="/EmployeeDashboard" /> 
                                : <PublicLayout><Login /></PublicLayout>} 
                />

                {/* Private Routes */}
                <Route path="/EmployeeDashboard" 
                       element={isAuthenticated 
                                ? <AuthLayout><Dashboard /></AuthLayout> 
                                : <Navigate to="/Login" />} 
                />
                <Route path="/EmployeeProfile" 
                       element={isAuthenticated 
                                ? <AuthLayout><Profile /></AuthLayout> 
                                : <Navigate to="/Login" />} 
                />
            </Routes>
        </Router>
    );
}

export default App;