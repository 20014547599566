import React, { useContext } from 'react'
import { AuthContext } from '../AuthContext'
import { useNavigate } from 'react-router-dom';

export default function LogoutBtn() {
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const handleLogout = () => {
        logout();
        navigate("/Login");
    };
    return (
        <button className="btn btn-link nav-link" onClick={handleLogout}>Logout</button>
    )
}
