import { jwtDecode } from 'jwt-decode';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';

export default function Profile() {
    const [employee, setEmployee] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { isAuthenticated, logout } = useContext(AuthContext);

    useEffect(() => {
        document.title = "Profile - BarakahByte";

        const fetchEmployeeData = async () => {
            setLoading(true);
            setError(null);

            try {
                const token = localStorage.getItem('BByteAuthToken');
                if (!isAuthenticated) {
                    logout();
                    navigate('/Login');
                    return;
                }
                const decodedToken = jwtDecode(token);
                const userId = decodedToken.UserId;
                const endPoint = process.env.REACT_APP_API_URL || 'https://barakahbyte.in/';
                const apiUrl = `${endPoint}EmployeesAPI/${userId}/GetEmployee`;

                const response = await fetch(apiUrl, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch employee data');
                }

                const data = await response.json();
                setEmployee(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchEmployeeData();
    }, [navigate, isAuthenticated, logout]);

    if (loading) {
        return <div className="text-center">Loading...</div>;
    }

    if (error) {
        return <div className="text-center text-danger">{error}</div>;
    }

    return (
        <section className="container mt-5">
            <h2 className="mb-4">
                Employee Profile
            </h2>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-4">
                            <img
                                src={employee.photo || "https://via.placeholder.com/150"}
                                alt="Employee Profile"
                                className="img-thumbnail"
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="col-md-8">
                            <h3>{employee.firstName} {employee.middleName} {employee.lastName}</h3>
                            <p><strong>Username:</strong> {employee.username}</p>
                            <p><strong>Email:</strong> {employee.email}</p>
                            <p><strong>Phone:</strong> +{employee.phone}</p>
                            <p><strong>DOB:</strong> {new Date(employee.dob).toLocaleDateString()}</p>
                            <p><strong>Gender:</strong> {employee.gender}</p>
                            <p><strong>UIDAI:</strong> {employee.uidai}</p>
                            <p><strong>PAN No:</strong> {employee.panNo}</p>
                            <p><strong>Joining Date:</strong> {new Date(employee.joiningDate).toLocaleDateString()}</p>
                            <p><strong>Status:</strong> {employee.isActive ? 'Active' : 'Inactive'}</p>
                            <p><strong>Role ID:</strong> {employee.roleId}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
